import React, { useEffect, useState } from "react"
import SEO from "../../components/seo"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import { Tint } from "../../components/SharedStyledComponents"
import GatsbyImage from "gatsby-image"
import { useDispatch, useSelector } from "react-redux"
import { login, reset, loginHotelUsers } from "./loginSlice"
import { ErrorInfo, Profile, Resource, Status } from "../../redux/types"
import { Dispatch, RootState } from "../../redux/store"
import StyledTextField, { PasswordTextField } from "../../components/TextFieldInput"
import StyledButton from "../../components/StyledButton"
import validate from "./LoginFormValidationRules"
import useForm from "../register/useForm"
import BackdropLoader from "../../components/BackdropLoader"
import { REGISTER, WORKOUTS_HOME } from "../routing/Locations"
import StyledSnackbar from "../../components/StyledSnackbar"
import AlertDialog from "../../components/AlertDialog"
import { LOGIN_ERROR_USER_NOT_FOUND, LOGIN_ERROR_WRONG_PASSWORD, LoginDetails } from "./types"
import { Box, Typography } from "@material-ui/core"
import MenuWithLogo from "../menu/MenuWithLogo"
import ResetPasswordDialog from "../account/ResetPassword/ResetPasswordDialog"
import { PARTNER_CLIENT_DATA } from "../../env"


// @ts-ignore
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center"

  },
  image: {
    height: "100vh",
    zIndex: "-2"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "280px", // Fix IE 11 issue.
    marginTop: theme.spacing(4)
  },
  submit: {
    margin: theme.spacing(3, 0, 1)
  }
}))

export default function Login() {
  const { desktop } = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "DSC02447.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1024, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )

  const classes = useStyles()
  const profileResource = useSelector((state: RootState) => state.login)
  const redirectSuccess = useSelector((state: RootState) => state.route.redirectSuccess)
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  const [passwordError, setPasswordError] = useState<string | undefined>()
  const dispatch = useDispatch()

  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useForm(() => dispatch(login(values as LoginDetails)), validate)

  useEffect(() => {
    if (profileResource.status == Status.FINISHED && profileResource.data) {
      navigate(redirectSuccess != null && redirectSuccess.length > 0 ? redirectSuccess : WORKOUTS_HOME, { replace: true })
    } else if (profileResource.status == Status.ERROR && profileResource.error) {
      if (profileResource.error.code == LOGIN_ERROR_WRONG_PASSWORD)
        setPasswordError(profileResource.error.message)
    }
  }, [profileResource])


  // Single Image Data
  const imageData = desktop.childImageSharp.fluid
  return (
    <div>
      <SEO title="Login"/>
      <MenuWithLogo memberMenu={false} showWelcome={false} blackIcon={false}/>
      <Grid container className={classes.root}>
        <Tint/>
        <Grid item xs={false} sm={4} md={5}>
          <GatsbyImage
            fluid={imageData}
            className={classes.image}
            imgStyle={{ objectFit: "cover" }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={7}>
          <div className={classes.paper}>
            <Typography variant={"h2"}> TIME TO SWEAT</Typography>
            <Box mt={1}>
              <Typography variant={"body2"}>
                Ready when you are.
              </Typography>
            </Box>
            {<>
              <div className={classes.form}>
                <StyledTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Enter email"
                  name="email"
                  autoComplete="email"
                  value={values.email || ""}
                  helperText={errors.email}
                  onChange={handleChange}
                  error={errors.email !== undefined}
                />
                <PasswordTextField
                  show={false}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Enter your password"
                  id="password"
                  helperText={passwordError}
                  error={passwordError !== undefined}
                  onChange={handleChange}
                  autoComplete="current-password"
                />
                <StyledButton
                  text="Bring it on"
                  onClick={handleSubmit}
                  className={classes.submit}/>

              </div>
              <Link to={REGISTER}>
                <Typography variant={"body2"}>I don’t have an account yet</Typography>
              </Link>
              <Link onClick={() => setShowForgotPassword(true)} to={"#"}>
                <Typography variant={"body2"}>Forgot password:(</Typography>
              </Link>
            </>}
            {showErrors(profileResource, dispatch)}
            <BackdropLoader show={profileResource.status == Status.LOADING}/>
          </div>
        </Grid>
      </Grid>

      <ResetPasswordDialog mode={"reset"} open={showForgotPassword} onClose={() => setShowForgotPassword(false)}/>
    </div>
  )
}

function showErrors(resource: Resource<Profile>, dispatch: Dispatch) {
  if (resource.status != Status.ERROR || !resource.error)
    return null

  // these emails are handled via the useeffect to poluate fields
  if (resource.error.code == LOGIN_ERROR_WRONG_PASSWORD)
    return null

  if (resource.error.code == LOGIN_ERROR_USER_NOT_FOUND)
    return showNoAccountError(resource.error, dispatch)


  return <StyledSnackbar show={true} severity={"error"} message={resource.error.message}/>
}

function showNoAccountError(errorInfo: ErrorInfo, dispatch: Dispatch) {
  return <AlertDialog show={true} message={errorInfo.message} positiveButton="register" positiveClick={() => {
    dispatch(reset())
    navigate(REGISTER, { replace: true })
  }
  }/>
}
