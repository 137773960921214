import React, { useEffect } from "react"
import Mbs from "../../images/clients/hotel_mbs.svg";
import { graphql, navigate, useStaticQuery } from "gatsby"
import SEO from "../../components/seo"
import Typography from "@material-ui/core/Typography"
import StyledButton from "../../components/StyledButton"
import { makeStyles } from "@material-ui/core/styles"
import { BGBlurImage } from "../../components/BGBlurImage"
import { useDispatch, useSelector } from "react-redux"
import { Profile, Resource, Status } from "../../redux/types"
import { WORKOUTS_HOME } from "../routing/Locations"
import { Dispatch, RootState } from "../../redux/store"
import { PARTNER_CLIENT_DATA } from "../../env"
import { loginHotelUsers } from "./loginSlice"
import BackdropLoader from "../../components/BackdropLoader"
import StyledSnackbar from "../../components/StyledSnackbar"
import Img from "gatsby-image"
import { isMobile } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "60vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    [`@media (min-aspect-ratio: 5 / 2)`]: {
      height: '70vh'
    },
    [`@media (orientation: landscape)`]: {
      justifyContent: isMobile ? "center" : 'flex-end',
    }
  },
  poweredRoot: {
    alignSelf: 'flex-end',
    width: "100%",
    height: "150px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  message: {
    marginTop: '1%', //theme.spacing(6),
    width: "70%"
  },

  vpnRoot: {
    marginTop: theme.spacing(4), //theme.spacing(6),
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
  },

  clientLogo: {
    maxWidth: "45%",
    maxHeight: "30%",
    objectFit: "contain",
    marginBottom: '1rem',
    marginTop: '0.25rem'
  },

  logo: {
    //maxWidth: "180px",
    maxWidth: "26vh",
    width: "28%",
    objectFit: "contain",
    position: "absolute",
    marginTop: theme.spacing(1)
  },
  homeButton: {
    width: "260px",
    margin: `${theme.spacing(3)}px auto 0px auto`,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px"
    }
  },
  mainRoot: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [`@media (orientation: portrait)`]: {
      paddingBottom: '2rem'
    },
    [`@media (orientation: landscape)`]: {
      paddingBottom: '1rem'
    }
  }
}))

const LoginPartnerClient = () => {
  const classes = useStyles()
  const profileResource = useSelector((state: RootState) => state.login)
  const redirectSuccess = useSelector((state: RootState) => state.route.redirectSuccess)


  const dispatch = useDispatch()



  const data = useStaticQuery(graphql`
      query {
          bg : file(relativePath: { eq: "frankie_pose.jpeg" }) {
              childImageSharp {
                  fluid(maxWidth: 2048, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          logo : file(relativePath: { eq: "haus-live-logo.png" }) {
              childImageSharp {
                  fluid(webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `)

  useEffect(() => {
    if (profileResource.status == Status.FINISHED && profileResource.data) {
      navigate(redirectSuccess != null && redirectSuccess.length > 0 ? redirectSuccess : WORKOUTS_HOME, { replace: true })
    }
  }, [profileResource])

  useEffect(() => {
    dispatch(loginHotelUsers(PARTNER_CLIENT_DATA))
  }, [])

  return <div className={classes.mainRoot}>
    <SEO title="Login" />
    <BGBlurImage imageUrl={""} localImage={data.bg.childImageSharp.fluid} />
    <div className={classes.root}>
      <Mbs className={classes.clientLogo} />
      <Typography className={classes.message} variant={"h2"} align={"center"}>Connect to your hotel wifi to view fitness classes</Typography>
      <StyledButton className={classes.homeButton} text="I am connected" onClick={() => dispatch(loginHotelUsers(PARTNER_CLIENT_DATA))} />
    </div>
    <span className={classes.vpnRoot}>
      <Typography variant={"h4"} align={"center"}><i>Please disable any VPN. For Apple users, please turn off the iCloud Private Relay in your device to use this service. <br/><br/><a href="https://support.apple.com/en-us/HT212614">Tap here for more infomation </a></i></Typography>
    </span>

    <div className={classes.poweredRoot}>
      <Typography className={classes.message} variant={"h4"} align={"center"}>Powered by</Typography>
      <Img className={classes.logo} fluid={data.logo.childImageSharp.fluid} />
    </div>
    {showErrors(profileResource, dispatch)}
    <BackdropLoader show={profileResource.status == Status.LOADING} />
  </div>
}

function showErrors(resource: Resource<Profile>, dispatch: Dispatch) {
  if (resource.status != Status.ERROR || !resource.error)
    return null

  return <StyledSnackbar show={true} severity={"error"} message={resource.error.message} />
}

export default LoginPartnerClient
