import React from "react"
import Login from "../features/login/Login"
import Layout from "../components/layout"
import { AnonymousOnlyRoute } from "../features/routing/Routes"
import { isParnterClientUser } from "../utils"
import LoginPartnerClient from "../features/login/LoginPartnerClient"

const LoginPage = () => {

  return (
    <Layout>
      <AnonymousOnlyRoute component={isParnterClientUser() ? LoginPartnerClient :Login}/>
    </Layout>
  )
}

export default LoginPage
